import moment from 'moment';

export default function formatToGlobalDate(date, complete = false) {
  // Format date to 'MM/DD/YYYY'
  // if (!date) {
  //   return moment(new Date()).format('MM/DD/YYYY');
  // }
  // if (complete) {
  //   return moment(new Date(date)).format('MM/DD/YYYY HH:mm');
  // }
  // return moment(new Date(date)).format('MM/DD/YYYY');

  if (!date) {
    return moment(new Date()).format('DD/MM/YYYY');
  }
  if (complete) {
    return moment(new Date(date)).format('DD/MM/YYYY HH:mm');
  }
  return moment(new Date(date)).format('DD/MM/YYYY');
}
